import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Stack from '../../../../components/Stack';
import styles from './HeaderCategory.module.scss';

export default function HeaderCategory({ className, category, setTarget }) {
  const [expand, setExpand] = useState();
  const id = category.slug;

  useEffect(() => {
    let mounted = true;
    const handler = () => {
      if (mounted) {
        setExpand(window.location.hash.slice(1).startsWith(id));
      }
    };

    window.addEventListener('hashchange', handler);
    handler();
    return () => {
      mounted = false;
      window.removeEventListener('hashchange', handler);
    };
  }, [id]);

  return (
    <li className={classnames(className, styles.category)} key={category.slug}>
      <a href={`#${id}`} onClick={() => setTarget(id)} className={styles.label}>
        {category.name} Commands
      </a>
      <Stack
        className={classnames(
          styles.commands,
          expand || styles[`commands--hidden`],
        )}
        spacing='element'
      >
        {category.commands.map(({ command }, i) => (
          <a
            href={`#${id}-${command.slice(1)}`}
            onClick={() => setTarget(`${id}-${command.slice(1)}`)}
            className={styles.command}
            key={i}
          >
            {command}
          </a>
        ))}
      </Stack>
    </li>
  );
}
