import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Button.module.scss';

export default function Button({
  as: Container,
  className,
  children,
  variant,
  color,
  icon,
  ...props
}) {
  return (
    <Container
      className={classnames(
        styles[`container--color-${color}`],
        styles[`container--${variant}`],
        styles.container,
        className,
      )}
      {...props}
    >
      {icon?.left && (
        <icon.left className={classnames(styles.icon, styles[`icon--left`])} />
      )}
      {children}
      {icon?.right && (
        <icon.right
          className={classnames(styles.icon, styles[`icon--right`])}
        />
      )}
    </Container>
  );
}

Button.defaultProps = {
  variant: 'outline',
  color: 'default',
  as: 'button',
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  color: PropTypes.oneOf(['default', 'primary', 'alt']),
  variant: PropTypes.oneOf(['outline', 'solid']),
  icon: PropTypes.shape({
    left: PropTypes.func,
    right: PropTypes.func,
  }),
  className: PropTypes.string,
};
