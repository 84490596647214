import React from 'react';
import { FaLink } from 'react-icons/fa';
import classnames from 'classnames';
import CommandGroupItem from './CommandGroupItem';
import Stack from '../../../components/Stack';
import styles from './CommandGroup.module.scss';

export default function CommandGroup({ data, className }) {
  return (
    <div id={data.slug} className={classnames(styles.container, className)}>
      <div className={styles.heading}>
        <a href={`#${data.slug}`} className={styles[`heading-link`]}>
          <FaLink />
        </a>
        <h2>{data.name} Commands</h2>
      </div>
      <Stack spacing='container' direction='column'>
        {data.commands?.map((item) => (
          <CommandGroupItem key={item.command} data={data} item={item} />
        ))}
      </Stack>
    </div>
  );
}
