import React from 'react';
import classnames from 'classnames';
import formatDate from '../../../utils/formatDate';
import Button from '../../../components/Button';
import Stack from '../../../components/Stack';
import styles from './Header.module.scss';
import HeaderCategory from './HeaderCategory';

export default function Header({
  className,
  categories,
  last_updated,
  setTarget,
}) {
  return (
    <Stack
      className={classnames(className, styles.container)}
      direction='column'
      as='nav'
    >
      <div>
        <h1 className={styles.heading}>Bot Commands</h1>
        <p className={styles.blurb}>
          These are the available commands for PokeMeow! Last updated @{' '}
          <span className={styles.highlight}>{formatDate(last_updated)}</span>.
        </p>
      </div>

      <h2 className={styles.subheading}>Categories</h2>
      <Stack
        as='ul'
        direction='column'
        spacing='element'
        className={styles.categories}
      >
        {categories.map((category) => (
          <HeaderCategory
            setTarget={setTarget}
            category={category}
            key={category.slug}
          />
        ))}
      </Stack>
      <Button className={styles.button} as='a' href='#'>
        Back to top
      </Button>
    </Stack>
  );
}
