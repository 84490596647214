import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Container from '../../components/Container';
import Layout from '../../components/Layout';
import Stack from '../../components/Stack';
import CommandGroup from './CommandGroup';
import Header from './Header';
import styles from './commands.module.scss';

export default function Commands({ data, pageContext }) {
  const last_updated = useRef(new Date(pageContext.last_updated));
  const { categories } = data.contentfulFetchCommandGroups;
  const [target, setTarget] = useState();

  useEffect(() => {
    let first = true;
    const observer = new IntersectionObserver(([entry]) => {
      if (first) {
        first = false;
        return;
      }

      const { isIntersecting, target: t, boundingClientRect } = entry;
      const curr = window.location.hash.slice(1);
      let el;

      if (boundingClientRect.top > 0) {
        el = isIntersecting ? t : t.previousSibling;
      } else {
        el = isIntersecting ? t : t.nextSibling;
      }

      if (!el || el.id === curr) return;
      if (!target) window.location.hash = `#${el.id}`;
    });

    categories.forEach(({ slug }) => {
      observer.observe(document.getElementById(slug));
    });
    return () => observer.disconnect();
  }, [categories, target]);

  useEffect(() => {
    if (target) {
      let timer;
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          timer = window.setTimeout(() => setTarget(), 1000);
        }
      });

      observer.observe(document.getElementById(target));
      return () => {
        window.clearTimeout(timer);
        observer.disconnect();
      };
    }
  }, [target]);

  return (
    <Layout title='Commands'>
      <Container className={styles.container} as={Stack} spacing='section'>
        <Header
          last_updated={last_updated.current}
          className={styles.heading}
          categories={categories}
          setTarget={setTarget}
        />
        <Stack className={styles.content} direction='column' spacing='element'>
          {categories.map((category) => (
            <CommandGroup
              key={category.slug}
              data={category}
              setTarget={setTarget}
            />
          ))}
        </Stack>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    contentfulFetchCommandGroups(contentful_id: { eq: $id }) {
      categories {
        name
        slug
        commands {
          command
          usages {
            usage
            description
          }
          aliases
          description {
            json
          }
        }
      }
    }
  }
`;
