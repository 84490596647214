import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FaLink } from 'react-icons/fa';
import classnames from 'classnames';
import Stack from '../../../../components/Stack';
import styles from './CommandGroupItem.module.scss';

export default function CommandGroupItem({ data, item, className }) {
  return (
    <div className={classnames(className, styles.item)}>
      <span
        className={styles.anchor}
        id={`${data.slug}-${item.command.slice(1)}`}
      />
      <div className={styles.label}>
        <h3>{item.command}</h3>
        <a
          href={`#${data.slug}-${item.command.slice(1)}`}
          className={classnames(
            styles[`heading-link--small`],
            styles[`heading-link`],
          )}
        >
          <FaLink />
        </a>
      </div>
      <div className={styles.about}>
        {documentToReactComponents(item.description.json)}
      </div>
      {item.usages && (
        <>
          <h4 className={styles[`item__subtitle`]}>Usages</h4>
          <Stack
            className={styles.list}
            direction='column'
            spacing='element'
            as='ul'
          >
            {item.usages.map(({ usage, description }, i) => (
              <li className={styles.usage} key={i}>
                <div className={styles[`code__wrapper`]}>
                  <span className={styles.code}>{usage}</span>
                </div>
                <p className={styles[`usage__desc`]}>{description}</p>
              </li>
            ))}
          </Stack>
        </>
      )}
      {item.aliases && (
        <>
          <h4 className={styles[`item__subtitle`]}>Aliases</h4>
          <Stack className={styles.list} as='ul' spacing='element'>
            {item.aliases.map((alias) => (
              <li className={styles.alias} key={alias}>
                <span className={styles.code}>{alias}</span>
              </li>
            ))}
          </Stack>
        </>
      )}
    </div>
  );
}
